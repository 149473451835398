import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  PromptValue,
  RedirectRequest,
} from '@azure/msal-browser';
import { filter, map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Observable, merge, of } from 'rxjs';

import { msalConfig } from './auth-config';
import { MemberIdentity } from '@v2/core/security/models/memberidentity';
import { Company } from '@v2/core/security/models/company';
import { BrainnetPrincipal } from '@v2/core/security/models/brainnetprincipal';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  isIframe = false;
  readonly boabaasUser$: Observable<BoabaasUser>;
  readonly principal$: Observable<BrainnetPrincipal>;
  readonly isAdmin$: Observable<boolean>;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) {
    this.boabaasUser$ = msalBroadcastService.inProgress$.pipe(
      filter((status) => status === InteractionStatus.None),
      map(() => {
        const acc = this.getCurrentAccount();
        return new BoabaasUser(acc);
      }),
      shareReplay(1),
    );

    this.isAdmin$ = this.boabaasUser$.pipe(map((boabaasUser) => boabaasUser?.isAdmin ?? false));

    // backward compatible access to BrainnetPrincipal
    this.principal$ = msalBroadcastService.inProgress$.pipe(
      filter((status) => status === InteractionStatus.None),
      switchMap(() => {
        const acc = this.getCurrentAccount();
        if (acc === null || acc === undefined) {
          const signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
            authority: msalConfig.policies.signUpSignIn.authority,
            scopes: msalConfig.scopes,
            prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
          };

          return this.login(signUpSignInFlowRequest).pipe(mergeMap((_) => of()));
        } else {
          const claims = acc.idTokenClaims;
          if (claims === null || claims === undefined) {
            return of();
          }
          return of(new BrainnetPrincipal(claims));
        }
      }),
      shareReplay(1),
    );
  }

  getToken(): Observable<string> {
    return this.msalBroadcastService.msalSubject$.pipe(
      map((eventMessage) => {
        if (eventMessage.eventType === 'msal:acquireTokenSuccess' && eventMessage.payload) {
          const request = eventMessage.payload as AuthenticationResult;
          return request.accessToken;
        }
        return null;
      }),
      filter((accessToken) => !!accessToken),
    ) as Observable<string>;
  }

  /*
  private createPrincipal(accountInfo: AccountInfo | null) {
    if (accountInfo === null || accountInfo === undefined) {
      return null;
    }

    const claims = accountInfo.idTokenClaims;
    if (claims === null || claims === undefined) {
      return null;
    }

    return new BrainnetPrincipal(claims as any);
  }
  */

  private getCurrentAccount = () => {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */

    let activeAccount = this.msalService.instance.getActiveAccount();
    if (isValidAccount(activeAccount)) {
      return activeAccount;
    }

    var accounts = this.msalService.instance.getAllAccounts();
    if (accounts.length === 0) {
      return null;
    }

    for (const acc of accounts) {
      if (isValidAccount(acc)) {
        return acc;
      }
    }

    return null;

    function isValidAccount(account: AccountInfo | null) {
      const exp = account?.idTokenClaims?.exp || 0;

      // check account is not expired
      if (exp * 1000 < Date.now()) {
        return false;
      }

      return msalConfig.clientId == account?.idTokenClaims?.aud;
    }
  };

  public bootstrap() {
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    const inProgress$ = this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      tap(() => {
        // this.setLoginDisplay();
        // this.checkAndSetActiveAccount();
      }),
    );

    const successEvent$ = this.msalBroadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      tap((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
      }),
    );

    const failureEvents$ = this.msalBroadcastService.msalSubject$.pipe(
      filter(
        (msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE,
      ),
      switchMap((result: EventMessage) => {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority: msalConfig.policies.resetPassword.authority,
            scopes: [],
          };

          return this.login(resetPasswordFlowRequest);
        }
        return of(undefined);
      }),
    );

    return merge(inProgress$, successEvent$, failureEvents$);
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest): Observable<unknown> {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        return this.msalService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as PopupRequest)
          .pipe(
            tap((response: AuthenticationResult) => {
              this.msalService.instance.setActiveAccount(response.account);
            }),
          );
      } else {
        return this.msalService.loginPopup(userFlowRequest).pipe(
          tap((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          }),
        );
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        return this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest);
      } else {
        return this.msalService.loginRedirect(userFlowRequest);
      }
    }
  }

  logout(): void {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalService.logoutPopup({
        account: this.msalService.instance.getActiveAccount(),
      });
    } else {
      this.msalService.logoutRedirect({
        account: this.msalService.instance.getActiveAccount(),
      });
    }
  }
}

export class BoabaasUser {
  private readonly memberStorageKey: string = 'activemember';
  constructor(private accountInfo: AccountInfo | null) {}

  get languageCode() {
    const claim = this.getClaim('languageCode') as string | null;

    return claim;
  }

  get authenticated(): boolean {
    return !this.accountInfo;
  }

  get initials() {
    var value = this.getClaim('initials') as string;
    if (value) return value;

    if (this.isAdmin) {
      return 'PA';
    }

    return '?';
  }

  get fullName() {
    return this.accountInfo?.name;
  }

  private getClaim(key: string) {
    const idTokenClaims = this.accountInfo?.idTokenClaims;
    if (!idTokenClaims) return null;

    return idTokenClaims[key];
  }

  get identities() {
    const members = this.getClaim('member_identities') as Array<string>;
    if (!members) return null;

    const memberIdentities = members.deserialize((_) => MemberIdentity.deserialize(_));

    return memberIdentities;
  }

  get companies(): Company[] | null {
    const { identities } = this;
    if (!identities) return null;

    const companies: Company[] = [];
    for (const identity of identities) {
      for (const company of identity.companies) {
        companies.push(company);
      }
    }

    return companies;
  }

  get isAdmin(): boolean {
    const isAdmin = this.getClaim('extension_IsAdmin');
    if (isAdmin === true) {
      return true;
    }

    if (environment.isProduction) {
      return false;
    }

    // when not production!!! and while extension_IsAdmin b2c custom user attribute is not yet
    // deployed or not yet in use then we fall back to checking on hardcoded user name.
    // if user portaladmin@magnitglobal.com then user is considered admin.
    // see also companion dotnet project to create portal admin in b2c tenant.
    const email = this.getClaim('email') as string;
    if (!email) return false;

    return email == 'portaladmin@magnitglobal.com';
  }

  get hasAgreedToEula(): boolean {
    const hasAgreedToEula = this.getClaim('extension_HasAgreedToEula') as boolean;

    return hasAgreedToEula;
  }

  get activeIdentity$() {
    const { identities } = this;
    if (!identities || identities.length === 0) {
      return null;
    }

    const activeIdentity = localStorage.getItem('activemember');

    for (const member of identities) {
      if (member.identifier.toString() === activeIdentity) {
        return of(member);
      }
    }

    if (identities.length === 0) return of(null);

    return of(identities[0]);
  }
}
