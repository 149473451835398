import { Pipe, PipeTransform } from '@angular/core';

import { ProposalStatus } from '~/v2/data/schema/generated/ProposalStatus';

@Pipe({
  name: 'proposalStatusClass',
})
export class ProposalStatusClassPipe implements PipeTransform {
  private warning: ProposalStatus[] = [ProposalStatus.Unspecified];

  private info: ProposalStatus[] = [
    ProposalStatus.Active,
    ProposalStatus.WaitingForProposal,
    ProposalStatus.Proposed,
    ProposalStatus.IntakePlanned,
    ProposalStatus.ConceptProposal,
  ];

  private danger: ProposalStatus[] = [
    ProposalStatus.Inactive,
    ProposalStatus.ProposalDeclined,
    ProposalStatus.DeclinedAfterIntake,
    ProposalStatus.JobRequestClosed,
  ];

  private success: ProposalStatus[] = [ProposalStatus.PlacementWillFollow, ProposalStatus.OnAssignment];

  private noColor: ProposalStatus[] = [ProposalStatus.InactiveJobRequestClosed];

  constructor() {}

  transform(proposalStatus: ProposalStatus): string {
    let returnedClasses = ``;

    if (this.warning.indexOf(proposalStatus) > -1) {
      returnedClasses = `bg-warning-100`;
    } else if (this.info.indexOf(proposalStatus) > -1) {
      returnedClasses = `bg-info-100`;
    } else if (this.danger.indexOf(proposalStatus) > -1) {
      returnedClasses = `bg-danger-100`;
    } else if (this.success.indexOf(proposalStatus) > -1) {
      returnedClasses = `alert-success`;
    }

    return returnedClasses;
  }
}
