import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { FeatherModule } from 'angular-feather';
import { TranslateModule } from '@ngx-translate/core';

import { AlertToastComponent } from './alert.toast';
import { AlertComponent } from './alert.component';

@NgModule({
  imports: [
    FeatherModule,
    TranslateModule,
    CommonModule,
    ToastrModule.forRoot({
      toastComponent: AlertToastComponent,
      preventDuplicates: true,
    }),
  ],
  declarations: [AlertToastComponent, AlertComponent],
  exports: [AlertToastComponent, AlertComponent],
})
export class AlertModule {}
