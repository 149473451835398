<div class="py-4 py-md-5 bg-background-end-user-license-agreement">
  <div class="container-xl container-hide-padding-mobile">
    <div class="image-container rounded">
      <img title="Magnit NL" src="https://brainnetsacdn.blob.core.windows.net/content/logo-magnit_300x108.png" />
    </div>
    <div class="end-user-license-agreement rounded">
      <div class="mt-4">
        <h1>{{ 'end-user-license-agreement.header' | translate }}</h1>
      </div>
      <div class="mt-4 mb-4">
        {{ 'end-user-license-agreement.instruction' | translate }}
      </div>
      <div class="scroll-outer-container rounded">
        <ng-scrollbar class="scroll-container" thumbClass="scrollbar-thumb" pointerEventsMethod="scrollbar">
          <div *ngIf="endUserLicenseAgreementText">
            <div [innerHTML]="endUserLicenseAgreementText"></div>
          </div>
        </ng-scrollbar>
      </div>
      <div *ngIf="endUserLicenseAgreementText" class="buttons mt-4">
        <button type="button" class="btn btn-primary min-w-100" (click)="accept()">
          {{ 'end-user-license-agreement.accept' | translate }}
        </button>
        <button type="button" class="btn btn-secondary min-w-100 ml-3" (click)="reject()">
          {{ 'end-user-license-agreement.reject' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
