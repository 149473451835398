<div class="row" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="col-1">
    <i-feather class="align-top mr-2" [name]="iconName!"></i-feather>
  </div>
  <div class="col-10 pr-0 pl-0">
    <div class="d-inline-flex ml-0 p-0">
      <span [innerHTML]="message" id="message"></span>
    </div>
  </div>
  <div class="col-1">
    <i-feather class="align-top mr-2" name="x"></i-feather>
  </div>
</div>
