import { Injectable, OnDestroy } from '@angular/core';
import { BrainnetPrincipal } from '../security/models/brainnetprincipal';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { Observable, Subject } from 'rxjs';
import { msalConfig } from '~/app/auth/auth-config';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
  ) {}

  get principal(): BrainnetPrincipal {
    const principal = this.createPrincipal(this.snapActiveAccount());
    if (!principal) {
      throw 'Not Authorized';
    }

    return principal;
  }

  public logout() {
    return this.msalService.logout();
  }

  public getPrincipal() {
    return this.getActiveAccount().pipe(map((acc) => this.createPrincipal(acc)));
  }

  public returnToHome(): void {
    this.router.navigate(['/supplier/home']);
  }

  private createPrincipal(accountInfo: AccountInfo | null) {
    if (accountInfo === null || accountInfo === undefined) {
      return null;
    }

    const claims = accountInfo.idTokenClaims;
    if (claims === null || claims === undefined) {
      return null;
    }

    return new BrainnetPrincipal(claims as any);
  }

  /**
   * Get active account asynchronously, emitting null when account could not be activated
   * @returns
   */
  public getActiveAccount(): Observable<AccountInfo | null> {
    const instance = this.msalService.instance;

    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$),
      startWith(null),
      map(selectAccount),
    );

    function selectAccount(): AccountInfo | null {
      let activeAccount = instance.getActiveAccount();
      if (isValidAccount(activeAccount)) {
        return activeAccount;
      }

      var accounts = instance.getAllAccounts();
      if (accounts.length === 0) {
        return null;
      }

      for (const acc of accounts) {
        if (isValidAccount(acc)) {
          return acc;
        }
      }

      return null;
    }

    function isValidAccount(account: AccountInfo | null) {
      return msalConfig.clientId == account?.idTokenClaims?.aud;
    }
  }

  private snapActiveAccount(): AccountInfo | null {
    const instance = this.msalService.instance;
    return selectAccount();

    function selectAccount(): AccountInfo | null {
      let activeAccount = instance.getActiveAccount();
      if (isValidAccount(activeAccount)) {
        return activeAccount;
      }

      var accounts = instance.getAllAccounts();
      if (accounts.length === 0) {
        return null;
      }

      for (const acc of accounts) {
        if (isValidAccount(acc)) {
          return acc;
        }
      }

      return null;
    }

    function isValidAccount(account: AccountInfo | null) {
      return msalConfig.clientId == account?.idTokenClaims?.aud;
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
