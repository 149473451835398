import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, of } from 'rxjs';

import { AuthService } from '~/v2/core/services/auth.service';
import { CacheService } from '~/v2/core/services/cache.service';
import { AlertService } from '~/v2/shared/components/alert/alert.service';
import { PortalHttpService } from './portal.http.service';
import { environment } from '@env';
import type { AvgCheckResponse } from '../schema/generated/AvgCheckResponse';

@Injectable({ providedIn: 'root' })
export class PrivacyStatementHttpService extends PortalHttpService {
  constructor(
    http: HttpClient,
    principal: AuthService,
    cacheService: CacheService,
    alertService: AlertService,
    languageService: TranslateService,
  ) {
    super(http, alertService, principal, cacheService, languageService, environment.v3.apiBaseUrl);
  }

  private checkFromCache(): AvgCheckResponse | null {
    const storageValue = localStorage.getItem('avg_check');
    if (!storageValue) {
      return null;
    }

    const cacheEntry = JSON.parse(storageValue) as AvgCheckCacheEntry;
    const checkTime = new Date().getTime();

    const since = checkTime - cacheEntry.checkTime;
    if (since < 1000 * 60 * 10 /* cache 10 min */) {
      return cacheEntry.value;
    }

    return null;
  }

  private updateCache(newValue: AvgCheckResponse) {
    const cacheEntry: AvgCheckCacheEntry = {
      checkTime: new Date().getTime(),
      value: newValue,
    };

    localStorage.setItem('avg_check', JSON.stringify(cacheEntry));
  }

  check(): Observable<AvgCheckResponse> {
    const fromCache = this.checkFromCache();
    if (fromCache) {
      return of(fromCache);
    }

    return super.get<{ value: AvgCheckResponse }>(`web/v1/avg/check`).pipe(
      map((result) => {
        var value: AvgCheckResponse = result.value ?? {
          hasAgreed: true,
          privacyStatement: null,
        };
        if (value.hasAgreed) {
          this.updateCache(value);
        }
        return value;
      }),
    );
  }

  accept(): Observable<any> {
    return super.post(`web/v1/avg/accept`);
  }

  reject(): Observable<any> {
    return super.post(`web/v1/avg/reject`);
  }
}

interface AvgCheckCacheEntry {
  checkTime: number;
  value: AvgCheckResponse;
}
