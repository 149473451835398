import { Guid } from 'guid-typescript';

import { GuidPipe } from '~/v2/shared/pipes/guid.pipe';
import { ContactRole } from './contactroles';
import { ScopePermission } from './scopepermission';

export class Company {
  private static delimeter = ';';
  private static roleDelimeter = '-';
  private static scopePermissionDelimeter = ',';

  identifier: Guid = Guid.parse(Guid.EMPTY);
  name = '';
  contactId: Guid = Guid.parse(Guid.EMPTY);
  roles: ContactRole[] = [];
  scopePermissions: ScopePermission[] = [];

  static deserialize(serializedCompany: string): Company {
    const chunks = serializedCompany.split(this.delimeter);
    const id = new GuidPipe().transform(chunks[0]);
    const cn = chunks[1];
    const ct = chunks[2];
    const ro = chunks[3];
    const sp = chunks[4];

    const companyName = cn as string;
    const contactId = ct === '' ? Guid.parse(Guid.EMPTY) : Guid.parse(ct);
    const roles = this.deserializeRoles(ro);
    const scopePermissions = this.deserializeScopePermissions(sp);

    return {
      identifier: id,
      name: companyName,
      contactId: contactId,
      roles: roles,
      scopePermissions: scopePermissions,
    };
  }

  private static deserializeRoles(value: string): ContactRole[] {
    const roles = value.split(this.roleDelimeter);
    const contactRoles: ContactRole[] = [];
    roles.forEach((role) => {
      const index = +role;
      const contactRole = <any>ContactRole[index];
      contactRoles.push(contactRole);
    });
    return contactRoles;
  }

  private static deserializeScopePermissions(value: string): any {
    const scopePermissions = value.split(this.scopePermissionDelimeter);
    return (scopePermissions as Array<string>).deserialize((_) => ScopePermission.deserialize(_));
  }
}
