import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { PipeModule } from '~/v2/shared/pipes/pipe.module';
import { DateLabelComponent } from './date-label.component';

@NgModule({
  imports: [TranslateModule, PipeModule],
  declarations: [DateLabelComponent],
  exports: [DateLabelComponent],
})
export class DateLabelModule {}
