import { Permission } from './permission';

export class ScopePermission {
  private static delimeter = ':';

  scope: string;
  permission: Permission;

  constructor(scope: string, permission: Permission) {
    this.scope = scope;
    this.permission = permission;
  }

  static deserialize(serialized: string): ScopePermission {
    const parts = serialized.split(this.delimeter);
    return this.construct(parts);
  }

  private static construct(parts: string[]): ScopePermission {
    const scope = parts[0];
    const permission = this.convert(parts[1]);
    return new ScopePermission(scope, permission);
  }

  private static convert(value: string): Permission {
    let permission: Permission = (<any>Permission)[value.toLocaleLowerCase()];
    if (permission == undefined) {
      permission = Permission.unknown;
    }
    return permission;
  }
}
