// Generated code based on TsInclude/TsExclude Attributes on .cs classes
// DO NOT CHANGE MANUALLY!
/* tslint:disable */

export enum ProposalStatus {
  Unspecified = 0,
  Active = 1,
  Inactive = 2,
  WaitingForProposal = 3,
  Proposed = 4,
  ProposalDeclined = 5,
  IntakePlanned = 6,
  DeclinedAfterIntake = 7,
  PlacementWillFollow = 8,
  OnAssignment = 9,
  InactiveJobRequestClosed = 10,
  ConceptProposal = 11,
  JobRequestClosed = 12,
}
export function proposalStatusAware(constructor: Function) {
  constructor.prototype.ProposalStatus = ProposalStatus;
}
