import { Guid } from 'guid-typescript';

import { Company } from './company';
import { SystemContext } from './systemcontext';
import { UserType } from '~/v2/data/schema/userType';
import { base64DecodeUnicode } from '../../helpers/characterEncoding';
import { GuidPipe } from '~/v2/shared/pipes/guid.pipe';
import { LocalStorage } from '@v2/core/helpers/localStorage';

const ACTIVEMEMBER_STORAGE_KEY: string = 'activemember';

export class MemberIdentity {
  static claimType = 'member_identities';
  private static supplierContactDiscriminator = 'lvcid';
  private static customerContactDiscriminator = 'klcid';
  private static professionalDiscriminator = 'lvdid';
  private static userTypeDiscriminator = 'userType';

  identifier: Guid = Guid.parse(Guid.EMPTY);
  systemContexts: SystemContext[] = [];
  companies: Company[] = [];
  userType: UserType = UserType.Unspecified;
  supplierContactId = '';
  customerContactId = '';
  professionalId = '';
  isActive: boolean = false;

  static deserialize(serializedMember: string): MemberIdentity {
    const decodedMember = base64DecodeUnicode(serializedMember);
    const obj = JSON.parse(decodedMember);
    return this.construct(obj);
  }

  private static construct(obj: any): MemberIdentity {
    const systemContexts = (obj.sc as Array<string>).deserialize((_) => SystemContext.deserialize(_));
    const companies = (obj.c as Array<string>).deserialize((_) => Company.deserialize(_));

    const supplierContactContext = systemContexts.findContext(this.supplierContactDiscriminator);
    const customerContactContext = systemContexts.findContext(this.customerContactDiscriminator);
    const professionalContext = systemContexts.findContext(this.professionalDiscriminator);
    const userTypeContext = systemContexts.findContext(this.userTypeDiscriminator);
    const userType = Number(userTypeContext.identifier);

    const activemember = localStorage.getItem(ACTIVEMEMBER_STORAGE_KEY);
    const guid: string = obj.id.toLowerCase().replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5');

    const memberIdentity: MemberIdentity = {
      identifier: Guid.parse(guid),
      systemContexts: systemContexts,
      companies: companies,
      userType: userType,
      professionalId: professionalContext.identifier,
      customerContactId: customerContactContext.identifier,
      supplierContactId: supplierContactContext.identifier,
      isActive: guid === activemember,
    };

    return memberIdentity;
  }
}
