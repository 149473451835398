<ng-container *ngIf="isOffline$ | async">
  <div
    class="border-4 border-magnit-blue p-4 fixed top-10 right-10 bottom-10 left-10 bg-white shadow-xl z-[1000] flex flex-col"
  >
    <div class="h-10">
      <button title="close" class="font-extrabold text-[30px] right-4 top-0 absolute" (click)="close()">
        <i-feather name="x" class="text-gray-500"></i-feather>
      </button>
    </div>
    <div class="max-w-[900px] m-auto">
      <div class="container" style="margin-left: auto; margin-right: auto; text-align: center">
        <h1 class="text-magnit-blue lg:text-4xl">Werkzaamheden Magnit portal</h1>

        <p>
          Vanwege werkzaamheden is onze portal tijdelijk niet beschikbaar. <br />
          Probeer op een ander moment opnieuw in te loggen.
        </p>

        <p>Onze excuses voor het ongemak.</p>

        <hr class="border-2 border-gray-300" />

        <h1 class="text-magnit-blue lg:text-4xl">Magnit portal maintenance</h1>

        <p>Our portal is currently unavailable due to maintenance. Please try again later.</p>

        <p>We apologise for the inconvenience.</p>
      </div>
    </div>
  </div>
</ng-container>
