<div class="login">
  <div class="login-top">
    <div class="d-flex p-2">
      <div class="flex-grow-1">
        <a class="navbar-brand m-0 p-0">
          <img
            class="br-mobile-logo"
            src="https://brainnetsacdn.blob.core.windows.net/content/logo-magnit_mark-only_204x270.svg"
            alt="Magnit"
            title="Magnit NL"
          />
        </a>
      </div>
      <div>
        <a [href]="registerLink" class="btn btn-primary btn-primary-mobile p-2">
          {{ 'register-button' | translate }}&nbsp;
          <i-feather [name]="'arrow-right'" class="svg-btn-primary"></i-feather>
        </a>
      </div>
      <div>
        <a class="btn btn-secondary p-2 ml-2" href="tel:{{ telephoneNumber }}"
          ><i-feather [name]="'phone'" class="svg-mobile"></i-feather
        ></a>
        <a class="btn btn-secondary p-2 ml-2" href="mailto:{{ emailAddress }}"
          ><i-feather [name]="'mail'" class="svg-mobile"></i-feather
        ></a>
      </div>
    </div>
  </div>

  <a class="navbar-brand m-0 p-0">
    <img
      src="https://brainnetsacdn.blob.core.windows.net/content/logo-magnit_tagline_635x229_transparant.svg"
      alt="Magnit"
      title="Magnit"
    />
  </a>

  <div class="login-container">
    <div class="login-left">
      <div
        [ngClass]="
          customerPortalIsLive
            ? 'login-left-container ml-0 mr-0 p-4 ml-lg-5 mr-lg-5 p-lg-5'
            : 'login-left-container-two-blocks ml-0 mr-0 p-4 ml-lg-5 mr-lg-5 p-lg-5'
        "
      >
        <div class="mb-5">
          <h1 id="br-welcome-header">{{ 'welcome-header' | translate }}</h1>
          <p>{{ 'welcome-text-1' | translate }}</p>
          <p>{{ 'welcome-text-2' | translate }}</p>
        </div>
        <div class="mb-5">
          <h2>{{ 'login-header' | translate }}</h2>
          <ng-container *ngIf="customerPortalIsLive">
            <div class="row ml-n3 mr-n3 width-three-blocks">
              <div class="col-md-12 col-lg-4 mb-3 pl-3 pr-3 d-block p-4 rounded login-option margin-three-blocks">
                <h2 class="login-option-title">
                  {{ 'login-supplier' | translate }}
                </h2>
                <a routerLink="/supplier/home" class="btn-portal">
                  {{ 'login-text' | translate }}&nbsp;
                  <i-feather [name]="'arrow-right'" class="svg-btn-portal"></i-feather>
                </a>
              </div>
              <div class="col-md-12 col-lg-4 mb-3 pl-3 pr-3 d-block p-4 rounded login-option margin-three-blocks">
                <h2 class="login-option-title">
                  {{ 'login-customer-migrated' | translate }}
                </h2>
                <a routerLink="/customer/home" class="btn-portal">
                  {{ 'login-text' | translate }}&nbsp;
                  <i-feather [name]="'arrow-right'" class="svg-btn-portal"></i-feather>
                </a>
              </div>
              <div class="col-md-12 col-lg-4 mb-3 pl-3 pr-3 d-block p-4 rounded margin-three-blocks">
                <h2 class="login-option-title">
                  {{ 'login-customer-not-migrated' | translate }}
                </h2>
                <a [href]="legacyCustomerPortalLink" class="btn-portal">
                  {{ 'login-text' | translate }}&nbsp;
                  <i-feather [name]="'arrow-right'" class="svg-btn-portal"></i-feather>
                </a>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!customerPortalIsLive">
            <div class="row ml-n3 mr-n3 width-two-blocks">
              <div class="col-md-12 col-lg-6 mb-3 pl-3 pr-3 d-block p-4 rounded login-option margin-two-blocks">
                <h2 class="login-option-title">
                  {{ 'login-supplier' | translate }}
                </h2>
                <a routerLink="/supplier/home" class="btn-portal">
                  {{ 'login-text' | translate }}&nbsp;
                  <i-feather [name]="'arrow-right'" class="svg-btn-portal"></i-feather>
                </a>
              </div>
              <div
                class="col-md-12 col-lg-6 mb-3 pl-3 pr-3 d-block p-4 rounded login-option-legacy-portal margin-two-blocks"
              >
                <h2 class="login-option-title">
                  {{ 'login-customer' | translate }}
                </h2>
                <a [href]="legacyCustomerPortalLink" class="btn-portal">
                  {{ 'login-text' | translate }}&nbsp;
                  <i-feather [name]="'arrow-right'" class="svg-btn-portal"></i-feather>
                </a>
              </div>
            </div>
          </ng-container>
        </div>
        <div>
          <h2>{{ 'login-netive' | translate }}</h2>
          <div>
            <ng-container *ngFor="let organisation of netiveOrganisations">
              <a [href]="netiveLink" class="btn btn-netive p-3 mr-2 mb-3" title="{{ organisation }}">
                {{ organisation }}
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="login-right">
      <div class="login-right-container p-5">
        <div class="mb-5">
          <h2>{{ 'register-header' | translate }}</h2>
          <p class="mb-4">{{ 'register-text' | translate }}</p>
          <a [href]="registerLink" class="btn btn-primary p-3">
            {{ 'register-button' | translate }}&nbsp;
            <i-feather name="arrow-right" class="svg-btn-primary"></i-feather>
          </a>
        </div>
        <div>
          <h3>{{ 'questions-header' | translate }}</h3>
          <div>
            <i-feather [name]="'phone'"></i-feather>&nbsp;<a href="tel:{{ telephoneNumber }}">{{ telephoneNumber }}</a>
          </div>
          <br />
          <div>
            <i-feather [name]="'mail'"></i-feather>&nbsp;<a href="mailto:{{ emailAddress }}">{{ emailAddress }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-maintenance-page></app-maintenance-page>
