import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ReplaySubject, takeUntil } from 'rxjs';
import { ActivatedRoute, Data } from '@angular/router';

import { EndUserLicenseAgreement, EndUserLicenseAgreementService } from './end-user-license-agreement.service';
import { GlobalConstants } from '@v2/configs/global.constants';
import { AuthenticationService } from '../auth/authentication.service';

@Component({
  selector: 'app-end-user-license-agreement',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgScrollbarModule],
  templateUrl: './end-user-license-agreement.component.html',
  styleUrls: ['./end-user-license-agreement.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EndUserLicenseAgreementComponent implements OnInit {
  endUserLicenseAgreementText!: string;

  private endUserLicenseAgreementId!: number;

  private readonly endUserLicenseAgreementService = inject(EndUserLicenseAgreementService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly authenticationService = inject(AuthenticationService);

  private readonly destroyed$ = new ReplaySubject(1);

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((response: Data) => {
      const languageCode = localStorage.getItem(GlobalConstants.languageLocalStorageItemKey);
      const endUserLicenseAgreement = response['endUserLicenseAgreement'] as EndUserLicenseAgreement;

      this.endUserLicenseAgreementId = endUserLicenseAgreement.id;

      if (languageCode === 'nl') {
        this.endUserLicenseAgreementText = endUserLicenseAgreement.dutchVersion;
      } else {
        this.endUserLicenseAgreementText = endUserLicenseAgreement.englishVersion;
      }
    });
  }

  accept(): void {
    this.endUserLicenseAgreementService
      .accept(this.endUserLicenseAgreementId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => (location.href = '/supplier/home'));
  }

  reject(): void {
    this.authenticationService.logout();
  }
}
