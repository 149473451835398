import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'localdate',
  pure: false,
})
export class LocalDatePipe implements PipeTransform {
  private timeZone = 'nl';
  private localeId: string;
  private static invalidDateValue = '0001-01-01T00:00:00+00:00';
  private static customDateFormats: any[] = [
    {
      locale: 'nl',
      formats: [
        { key: 'mediumDate', value: 'd MMM y' },
        { key: 'longDateWithoutYear', value: 'EEEE d LLLL' },
        { key: 'shortDateWithoutYear', value: 'dd/MM' },
      ],
    },
    {
      locale: 'en',
      formats: [
        { key: 'shortDate', value: 'MM/dd/yyyy' },
        { key: 'longDateWithoutYear', value: 'EEEE, LLLL d' },
        { key: 'shortDateWithoutYear', value: 'MM/dd' },
      ],
    },
  ];

  constructor(@Inject(LOCALE_ID) localeId: string) {
    this.localeId = localeId;
  }

  transform(value: any, format: string): any {
    if (!LocalDatePipe.isValidDate(value)) {
      return null;
    }

    const locale = this.localeId.toString();
    const customFormat = LocalDatePipe.getFormat(format, locale);

    const formatted = formatDate(value, customFormat, locale, this.timeZone);
    return formatted;
  }

  private static isValidDate(value: any): boolean {
    return !(!value || value == this.invalidDateValue || value.toString() == 'Invalid Date');
  }

  private static getFormat(format: string, locale: string): string {
    const isCustomFormat = this.customDateFormats.some(
      (x) => x.locale === locale && x.formats.some((y: any) => y.key == format),
    );

    if (isCustomFormat) {
      format = this.customDateFormats
        .filter((x) => x.locale === locale)[0]
        .formats.filter((x: any) => x.key == format)[0].value;
    }

    return format;
  }
}
