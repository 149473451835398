import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';

import { CacheService } from './cache.service';
import { AuthenticationService } from '~/app/auth/authentication.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  protected hubConnection: signalR.HubConnection | null = null;

  constructor(
    private authService: AuthenticationService,
    private cacheService: CacheService,
  ) {}

  init(signalRUrl: string): void {
    this.createConnection(signalRUrl);
    this.startConnection();
  }

  protected createConnection(signalRUrl: string): void {
    const { authService } = this;
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(signalRUrl, {
        accessTokenFactory() {
          return firstValueFrom(authService.getToken());
        },
      })
      .build();
  }

  protected startConnection(): void {
    this.hubConnection!.start()
      .then(() => {})
      .catch((err) => {
        console.log('Error while starting SignalR connection: ' + err);
      });
  }

  protected isOwnEvent(operationId: string): boolean {
    return this.cacheService.isExistingOperationId(operationId);
  }
}
