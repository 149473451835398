<!-- Main container with full-screen white background -->
<div class="relative w-screen h-screen bg-white">
  <!-- Company logo positioned at the top left -->
  <a routerLink="/">
    <img
      src="https://brainnetsacdn.blob.core.windows.net/content/logo-magnit_tagline_635x229_transparant.svg"
      alt="Magnit"
      title="Magnit"
      class="absolute m-6 w-36 cursor-pointer"
    />
  </a>

  <!-- Centered content container -->
  <div class="flex justify-center items-center w-full h-full">
    <!-- Form container with styling -->
    <div class="w-11/12 max-w-lg p-8 bg-magnit-blue shadow-lg rounded-lg text-white">
      <ng-container *ngIf="!isEmailSent; else emailSentMessage">
        <!-- Template for loading overlay -->
        <ng-template #loadingOverlay>
          <div class="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50">
            <mat-spinner diameter="50"></mat-spinner>
            <!-- Spinner for loading state -->
          </div>
        </ng-template>

        <!-- Conditionally displays loading overlay -->
        <ng-container *ngIf="isLoading; then loadingOverlay"></ng-container>

        <!-- Forgot Password heading -->
        <h2 class="mb-6 text-xl font-medium">
          {{ 'forgotPasswordHeader' | translate }}
        </h2>

        <!-- Instructional text for the user -->
        <p class="mb-6 font-normal">
          {{ 'forgotPasswordText' | translate }}
        </p>

        <!-- Form for email submission -->
        <form [formGroup]="emailForm" (ngSubmit)="submit()" [class.opacity-50]="isLoading">
          <!-- Email input field with Angular Material design -->
          <mat-form-field appearance="fill" class="w-full mb-8">
            <mat-label>{{ 'emailPlaceholder' | translate }}</mat-label>
            <input matInput placeholder="email@example.com" formControlName="email" required />
            <!-- Validation messages -->
            <mat-error *ngIf="emailForm.get('email')!.hasError('required')">
              {{ 'emptyEmail' | translate }}
            </mat-error>
            <mat-error *ngIf="emailForm.get('email')!.hasError('email')">{{ 'invalidEmail' | translate }} </mat-error>
          </mat-form-field>

          <button mat-raised-button color="warn" [disabled]="!emailForm.valid || isLoading" class="mt-4 text-white">
            {{ 'sendButton' | translate }}
            <mat-icon iconPositionEnd>arrow_forward</mat-icon>
          </button>
        </form>
      </ng-container>

      <ng-template #emailSentMessage>
        <h2 class="mb-6 text-xl font-bold">
          {{ 'emailSentHeader' | translate }}
        </h2>

        <p class="mb-6 font-normal">
          {{ 'emailSentText' | translate }}
        </p>

        <h4 class="mb-6 text-xl font-bold">
          {{ 'notReceivedHeader' | translate }}
        </h4>

        <p [innerHTML]="'notReceivedText' | translate" class="mb-6 font-normal"></p>
      </ng-template>
    </div>
  </div>
</div>
