import { Injectable, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  @Output() dataTableButtonMouseOverEvent: EventEmitter<any> = new EventEmitter();
  @Output() dataTableButtonMouseOutEvent: EventEmitter<any> = new EventEmitter();
  @Output() dataTableNeedsUpdate: EventEmitter<any> = new EventEmitter();
  @Output() rowDeletedEvent: EventEmitter<any> = new EventEmitter();
  @Output() draftDeletedEvent: EventEmitter<any> = new EventEmitter();
  @Output() jobRequestTaggedEvent: EventEmitter<any> = new EventEmitter();
  @Output() countsUpdatedEvent: EventEmitter<any> = new EventEmitter();
  @Output() proposalWithdrawnEvent: EventEmitter<any> = new EventEmitter();
  @Output() proposalRegistrationFailedEvent: EventEmitter<any> = new EventEmitter();
  @Output() proposalCreatedEvent: EventEmitter<any> = new EventEmitter();
  @Output() secondeeProposedEvent: EventEmitter<any> = new EventEmitter();
  @Output() secondeeRemovedEvent: EventEmitter<any> = new EventEmitter();
  @Output() secondeeEditEvent: EventEmitter<any> = new EventEmitter();
  @Output() draftCreatedEvent: EventEmitter<any> = new EventEmitter();
  @Output() screeningUpdatedEvent: EventEmitter<any> = new EventEmitter();
  @Output() newPicklistItemCreatedEvent: EventEmitter<any> = new EventEmitter();
  @Output() picklistItemUpdatedEvent: EventEmitter<any> = new EventEmitter();
  @Output() showGlobalLoaderEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() previousNavigatedRoute: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Output() screeningDelegatedEvent: EventEmitter<any> = new EventEmitter();
  @Output() bigNumberRegisteredEvent: EventEmitter<any> = new EventEmitter();
  @Output() delegatedIntegrityCheckStartedEvent: EventEmitter<any> = new EventEmitter();
  @Output() documentLinkedToScreeningItemEvent: EventEmitter<any> = new EventEmitter();
  @Output() formerEmployersRegisteredEvent: EventEmitter<any> = new EventEmitter();
  @Output() identityVerificationStartedEvent: EventEmitter<any> = new EventEmitter();
  @Output() integrityCheckStartedEvent: EventEmitter<any> = new EventEmitter();
  @Output() professionalMutationSavedEvent: EventEmitter<any> = new EventEmitter();
  @Output() languageChangedEvent: EventEmitter<any> = new EventEmitter();
  @Output() dropdownSelectedEvent: EventEmitter<any> = new EventEmitter();
  @Output() onExpenseDeleteClicked: EventEmitter<any> = new EventEmitter();
  @Output() onExpenseRevertClicked: EventEmitter<any> = new EventEmitter();
  @Output() introCompleted: EventEmitter<any> = new EventEmitter();
  @Output() onExpenseSaveClicked: EventEmitter<any> = new EventEmitter();
  @Output() onExpenseSubmitClicked: EventEmitter<any> = new EventEmitter();
  @Output() onHoursExpensesDownloadClicked: EventEmitter<any> = new EventEmitter();
  @Output() privacyStatement: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {}
}
