import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private operationIds: Array<{ operationId: string; date: Date }> = [];

  constructor() {}

  getOperationId(): string {
    const operationId = Guid.create().toString();
    const date = new Date();
    this.operationIds.push({ operationId, date });
    this.cleanupOperationIds();
    return operationId;
  }

  private cleanupOperationIds(): void {
    const now = new Date();
    const oneMinuteAgo = new Date();
    oneMinuteAgo.setMinutes(now.getMinutes() - 1);
    this.operationIds.filter((x) => x.date < oneMinuteAgo);
  }

  isExistingOperationId(operationId: string): boolean {
    const found = this.operationIds.some((x) => x.operationId === operationId);

    return found;
  }
}
