import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalGuard } from '@azure/msal-angular';

import { LandingComponent } from './landing/landing.component';
import { NotFoundComponent as NotFoundComponent } from './notfound/not-found.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';
import { EndUserLicenseAgreementComponent } from './end-user-license-agreement/end-user-license-agreement.component';
import { endUserLicenseAgreementResolver } from './end-user-license-agreement/end-user-license-agreement.resolver';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: 'privacy-statement',
    component: PrivacyStatementComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'end-user-license-agreement',
    component: EndUserLicenseAgreementComponent,
    resolve: { endUserLicenseAgreement: endUserLicenseAgreementResolver },
    canActivate: [MsalGuard],
  },
  {
    path: 'supplier',
    loadChildren: () => import('./supplier/supplier.module').then((e) => e.SupplierModule),
  },
  {
    /**  redirect invalid contract-signing urls */
    path: 'overviews/contracts/contract-signing',
    redirectTo: 'supplier/overviews/contracts/contract-signing',
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((e) => e.AdminModule),
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal,
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
