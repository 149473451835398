import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

import { GlobalConstants } from '@v2/configs/global.constants';
import { AuthenticationService } from '~/app/auth/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class TranslateExtensionService {
  private supportedLanguages: string[] = ['nl', 'en'];

  constructor(
    private translateService: TranslateService,
    private authService: AuthenticationService,
  ) {
    this.supportedLanguages.forEach((language) => this.setTranslation(language));
    this.translateService.addLangs(this.supportedLanguages);

    const cachedLanguageCode = localStorage.getItem(GlobalConstants.languageLocalStorageItemKey);

    if (cachedLanguageCode) {
      this.updateLanguageCode(cachedLanguageCode);
    } else {
      this.updateLanguageCode('en');
    }

    this.authService.boabaasUser$.subscribe((boabaasUser) => {
      if (!localStorage.getItem(GlobalConstants.languageLocalStorageItemKey)) {
        if (boabaasUser.languageCode) {
          this.updateLanguageCode(boabaasUser.languageCode);
          localStorage.setItem(GlobalConstants.languageLocalStorageItemKey, boabaasUser.languageCode);
        }
      }
    });
  }

  updateLanguageCode(languageCode: string) {
    this.setHtmlLanguage(languageCode);
    this.translateService.use(languageCode);
  }

  getTranslation(key: string, call: (data: any) => void): void {
    this.translateService
      .get(key)
      .pipe(take(1))
      .subscribe((data) => {
        call(data);
      });
  }

  getInstantTranslation(key: string, parameters?: any): any {
    try {
      return this.translateService.instant(key, parameters);
    } catch (ex) {
      return key;
    }
  }

  private setHtmlLanguage(language: string): void {
    document.querySelector('html')?.setAttribute('lang', language);
  }

  private setTranslation(language: string): void {
    const translations = require(`src/assets/i18n/${language}.json`);
    this.translateService.setTranslation(language, translations, true);
  }
}
