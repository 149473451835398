import { throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

export class ConsoleLogErrorHandler {
  static handleError(err: any): Observable<never> {
    let errorMessage = '';
    const message: string = err && err.error ? err.error.message : '';

    if (err) {
      if (err.error instanceof ErrorEvent) {
        errorMessage = `An error occurred: ${message}`;
      } else {
        errorMessage = `server returned code ${err.status}: ${message}`;
      }
    }

    console.error(err);
    return throwError(() => errorMessage);
  }
}
