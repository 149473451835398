import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyformat',
})
export class CurrencyFormatPipe implements PipeTransform {
  private decimalLength = 2;
  private chunkLength = 3;

  transform(value: string, currencySign = '€', thousandsSeparatorSymbol = '.', decimalSymbol = ','): string {
    if (!value) {
      return '';
    }

    value = value.toString();

    const valueNumber = +value.replace(currencySign, '').trim().replace(',', '.');

    const result = '\\d(?=(\\d{' + this.chunkLength + '})+' + (this.decimalLength > 0 ? '\\D' : '$') + ')';
    const num = valueNumber.toFixed(Math.max(0, ~~this.decimalLength));

    return (
      (currencySign ? currencySign + ' ' : '') +
      (decimalSymbol ? num.replace('.', decimalSymbol) : num).replace(
        new RegExp(result, 'g'),
        '$&' + thousandsSeparatorSymbol,
      )
    );
  }
}
