import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { catchError, delay, map, Observable, of, switchMap, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '@v2/core/services/auth.service';
import { CacheService } from '@v2/core/services/cache.service';
import { environment } from '@env';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class EndUserLicenseAgreementService {
  private readonly httpClient = inject(HttpClient);
  private readonly authService = inject(AuthService);
  private readonly cacheService = inject(CacheService);
  private readonly translateService = inject(TranslateService);
  private readonly authenticationService = inject(AuthenticationService);

  private httpHeaders: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  retrieve(): Observable<EndUserLicenseAgreement> {
    this.addHttpHeaders();
    return this.httpClient.get<EndUserLicenseAgreement>(
      environment.v3.apiBaseUrl + `/web/v1/EndUserLicenseAgreement/retrieve`,
    );
  }

  accept(endUserLicenseAgreementId: number): Observable<unknown> {
    const body = { endUserLicenseAgreementId };
    return this.httpClient
      .post(environment.v3.apiBaseUrl + `/web/v1/EndUserLicenseAgreement/accept`, body)
      .pipe(tap(() => this.updateCache(true)));
  }

  hasAgreed(): Observable<boolean> {
    // TODO uncomment once EULA will be released
    // const decision = this.checkFromCache();

    // if (decision) {
    //   return of(decision.hasAgreed);
    // }

    // return this.authenticationService.boabaasUser$.pipe(map((user) => user.hasAgreedToEula));
    return of(true);
  }

  private addHttpHeaders() {
    this.setActiveMemberHeader();
    this.setOperationIdHeader();
    this.setLanguageHeader();
  }

  private setActiveMemberHeader(): void {
    const headerName = 'x-active-member';
    const principal = this.authService.principal;
    if (principal) {
      const memberIdentifier = principal.activeMember.identifier.toString();
      this.addHeaderValue(headerName, memberIdentifier);
    }
  }

  private setOperationIdHeader(): void {
    const headerName = 'X-Operation-Id';
    const operationId = this.cacheService.getOperationId();

    this.addHeaderValue(headerName, operationId);
  }

  private setLanguageHeader(): void {
    const headerName = 'x-language-locale';
    const language = this.translateService.currentLang;

    this.addHeaderValue(headerName, language);
  }

  private addHeaderValue(name: string, value: any): void {
    if (!this.httpHeaders.has(name)) {
      this.httpHeaders = this.httpHeaders.append(name, value);
    } else if (this.httpHeaders.get(name) !== value) {
      this.httpHeaders = this.httpHeaders.set(name, value);
    }
  }

  private updateCache(hasAgreed: boolean): void {
    const cacheEntry: EndUserLicenseAgreementEntry = {
      hasAgreed: hasAgreed,
    };

    localStorage.setItem('eula_decision', JSON.stringify(cacheEntry));
  }

  private checkFromCache(): EndUserLicenseAgreementEntry | null {
    const storageValue = localStorage.getItem('eula_decision');
    if (!storageValue) {
      return null;
    }

    const cacheEntry = JSON.parse(storageValue) as EndUserLicenseAgreementEntry;

    return cacheEntry;
  }
}

export interface EndUserLicenseAgreement {
  id: number;
  dutchVersion: string;
  englishVersion: string;
}

interface EndUserLicenseAgreementEntry {
  hasAgreed: boolean;
}
