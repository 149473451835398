import { Component } from '@angular/core';

import { OfflineInterceptor } from '../../maintenance/offline-interceptor';

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
})
export class MaintenancePageComponent {
  isOffline$ = OfflineInterceptor.isOffline$;

  close() {
    OfflineInterceptor.isOffline$.next(false);
  }
}
