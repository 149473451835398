import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringClass',
})
export class StringClassPipe implements PipeTransform {
  private warning: string[] = [
    'screeningremarks.do-screening-action',
    'screeningremarks.contract-waiting-to-be-signed',
    'notstarted',
    'screeningactions.supplier',
    'screeningactions.professional',
  ];

  private info: string[] = [
    'screeningremarks.payment-proposal-waiting-on-approval',
    'brainnet',
    'stopped',
    'screeningactions.brainnet',
    'contractactions.supplier',
    'contractactions.brainnet',
  ];

  private danger: string[] = ['screeningremarks.do-many-actions'];

  private success: string[] = ['started', 'screeningremarks.wont-be-extended'];

  private clear: string[] = [
    'complete',
    'completed',
    'waiting',
    'cancelled',
    'contractactions.waiting-on-screening',
    'screeningactions.complete',
    'screeningactions.unspecified',
    'screeningactions.cancelled',
    'contractactions.unspecified',
    'contractactions.waiting-on-screening',
    'contractactions.complete',
  ];
  constructor() {}

  transform(action: string): string {
    let returnedClasses = ``;

    action = action.toLocaleLowerCase();

    if (this.warning.indexOf(action) > -1) {
      returnedClasses = `bg-warning-100`;
    } else if (this.info.indexOf(action) > -1) {
      returnedClasses = `bg-info-100`;
    } else if (this.danger.indexOf(action) > -1) {
      returnedClasses = `bg-danger-100`;
    } else if (this.success.indexOf(action) > -1) {
      returnedClasses = `alert-success`;
    } else if (this.clear.indexOf(action) > -1) {
    } else {
      //this is for variable text - like supplier name and professional name
      if (action) {
        returnedClasses = `bg-warning-100`;
      }
    }

    return returnedClasses;
  }
}
